import { Selector } from "state-manager";
import { RepositoryMovementsListingAll as Main } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/ListingAll";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { DataTypeId } from "types/src/DataType/DataType";
import { useStateBehavior } from "@Hooks/useStateBehavior";
import * as Fp from "fp-ts/function";
import { RepositoryMovementsListing } from "@Containers/Listing/RepositoryMovementsListing";
import * as O from "fp-ts/Option";
import * as Obj from "utils/object";

export namespace Content {
  export interface Props {
    selector: Selector<Main.State>;
    dispatch: (a: Main.Actions) => void;
    onCreateNewType: () => void;
    onItemClick: (id: RepositoryMovementId) => void;
    onTypeClick: (id: DataTypeId) => void;
  }
}

export function Content(p: Content.Props) {
  const state$ = useStateBehavior().map(p.selector);
  const data$ = state$.map(
    Fp.flow((s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return {
          entries: [],
          total: 0,
          pagination: {
            hasNext: false,
            hasPrev: false,
          },
        };

      return {
        entries: s.payload.items,
        total: s.payload.total,
        pagination: {
          hasPrev: s.payload.pageInfo.hasPreviousPage,
          hasNext: s.payload.pageInfo.hasNextPage,
        },
      };
    }),
  );
  const selected$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return [];

    return s.payload.selected;
  });
  const unselectable$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return [];

    return Obj.entries(s.payload.removing)
      .filter(([, v]) => v === "removing")
      .map((v) => v[0]);
  });

  return (
    <RepositoryMovementsListing
      loading$={state$.map(Main.instance.states.loading.is)}
      data$={data$}
      filters$={state$.map((v) => ({
        search: v.payload.filters.payload.fields.search,
        createdAt: v.payload.filters.payload.fields.createdAt,
      }))}
      bulkSelect={{
        selected$,
        unselectable$,
        onSelect: Fp.flow(Main.instance.actions.select.create, p.dispatch),
        onUnselect: Fp.flow(Main.instance.actions.unselect.create, p.dispatch),
        onAction: Fp.flow((v) => {
          switch (v) {
            case "remove":
              return Main.instance.actions.removeBulk.create();
            case "execute":
              return Main.instance.actions.executeBulk.create();
          }
        }, p.dispatch),
      }}
      remove={{
        confirmation$: state$.map((s) => {
          if (
            Main.instance.states.loading.is(s) ||
            Main.instance.states.loadError.is(s)
          )
            return false;

          return (
            Obj.values(s.payload.removing).filter((v) => v === "confirm")
              .length || false
          );
        }),
        onConfirm: Fp.flow(
          Main.instance.actions.removeConfirm.create,
          p.dispatch,
        ),
        onDecline: Fp.flow(
          Main.instance.actions.removeDecline.create,
          p.dispatch,
        ),
      }}
      execute={{
        confirmation$: state$.map((s) => {
          if (
            Main.instance.states.loading.is(s) ||
            Main.instance.states.loadError.is(s)
          )
            return false;

          return (
            Obj.values(s.payload.executing).filter((v) => v === "confirmation")
              .length || false
          );
        }),
        onConfirm: Fp.flow(
          Main.instance.actions.executeConfirm.create,
          p.dispatch,
        ),
        onDecline: Fp.flow(
          Main.instance.actions.executeDecline.create,
          p.dispatch,
        ),
      }}
      orderBy$={state$.map((v) => O.fromNullable(v.payload.order))}
      onCreateNewType={p.onCreateNewType}
      onCreateNew={undefined}
      onItemClick={p.onItemClick}
      onTypeClick={p.onTypeClick}
      onPageChange={Fp.flow(Main.instance.actions.setPage.create, p.dispatch)}
      onFilterChange={Fp.flow(
        Main.instance.subStates.filters.actions.setValue.create,
        p.dispatch,
      )}
      onOrderChange={Fp.flow(Main.instance.actions.orderBy.create, p.dispatch)}
    />
  );
}
