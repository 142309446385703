import { useTranslation } from "i18n";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import { OrderBy } from "types/src/OrderBy";
import { EntityListing } from "@Containers/Listing/EntityListing";
import { ListingTable } from "ui/layouts/ListingTable";
import { RepositoryId } from "types/src/Repositories/Repository";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { ExecuteModal } from "ui/layouts/Dialogs/ExecuteModal";

export namespace RepositoryMovementsListing {
  export interface Item {
    id: RepositoryMovementId;
    createdAt: ISODate;
    updatedAt: O.Option<ISODate>;
    dataType: O.Option<{ id: DataTypeId; name: string }>;
    repository: { id: RepositoryId; name: string };
    from: O.Option<{ id: RepositoryId; name: string }>;
    to: { id: RepositoryId; name: string };
  }

  export interface Props {
    data$: BehaviorValue<{
      total: number;
      entries: Item[];
      pagination: {
        hasNext: boolean;
        hasPrev: boolean;
      };
    }>;
    loading$: BehaviorValue<boolean>;
    filters$: BehaviorValue<{
      search: O.Option<NoEmptyString>;
      createdAt: DateRange;
    }>;
    orderBy$: BehaviorValue<O.Option<OrderBy<"createdAt" | "updatedAt">>>;
    bulkSelect: Omit<
      EntityListing.Props<Item, "remove" | "execute">["bulkSelect"],
      "actions"
    >;
    remove: Omit<
      EntityListing.Props<Item, "remove">["remove"],
      "getDescription" | "getTitle"
    >;
    execute: {
      confirmation$: BehaviorValue<false | number>;
      onConfirm: () => void;
      onDecline: () => void;
    };

    onCreateNew: (() => void) | undefined;
    onCreateNewType: () => void;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: RepositoryMovementId) => void;
    onTypeClick: (id: DataTypeId) => void;
    onFilterChange: (
      v: Partial<{
        search: O.Option<NoEmptyString>;
        createdAt: DateRange;
      }>,
    ) => void;
    onOrderChange: (p: "createdAt" | "updatedAt") => void;
  }
}

export function RepositoryMovementsListing(
  p: RepositoryMovementsListing.Props,
) {
  const { t } = useTranslation();

  return (
    <>
      <EntityListing
        title={t("Recent repository movements")}
        newItemTitle={t("New movement")}
        newTypeTitle={t("New movement type")}
        columns={{
          repository: {
            label: t("Repository"),
            type: ListingTable.CellType.text,
            renderProps: (v) => ({ text: v.repository.name }),
          },
          from: {
            label: t("From"),
            type: ListingTable.CellType.movedFrom,
            renderProps: (v) => ({ text: O.toUndefined(v.from)?.name }),
          },
          to: {
            label: t("To"),
            type: ListingTable.CellType.movedTo,
            renderProps: (v) => ({ text: v.to.name }),
          },
        }}
        data$={p.data$}
        onItemClick={p.onItemClick}
        onOrderChange={p.onOrderChange}
        onFilterChange={p.onFilterChange}
        orderBy$={p.orderBy$}
        filters$={p.filters$}
        onPageChange={p.onPageChange}
        loading$={p.loading$}
        onTypeClick={p.onTypeClick}
        onCreateNew={p.onCreateNew}
        onCreateNewType={p.onCreateNewType}
        bulkSelect={{
          ...p.bulkSelect,
          actions: [
            { id: "remove", label: t("Remove") },
            { id: "execute", label: t("Execute") },
          ],
        }}
        remove={{
          ...p.remove,
          getTitle: (c) =>
            c === 1 ? t("Remove movement") : t("Remove movements"),
          getDescription: (c) =>
            c === 1
              ? t("Do you really want to remove this movement")
              : t("Do you really want to remove this movements"),
        }}
      />
      <Execute {...p.execute} />
    </>
  );
}

interface ExecuteProps {
  confirmation$: BehaviorValue<false | number>;
  onConfirm: () => void;
  onDecline: () => void;
}

function Execute(p: ExecuteProps) {
  const { t } = useTranslation();
  const confirm = useBehaviorValue(p.confirmation$);

  return confirm !== false ? (
    <ExecuteModal
      title={confirm === 1 ? t("Execute movement") : t("Execute movements")}
      onConfirm={p.onConfirm}
      onCancel={p.onDecline}
    >
      {confirm === 1
        ? t("Please confirm that you want to execute this movement")
        : t("Please confirm that you want to execute this movements")}
    </ExecuteModal>
  ) : null;
}
